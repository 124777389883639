import { website } from '@/axios'

export default {
  getAllNotifications () {
    return website().get('/user/notifications')
  },
  upload (payload) {
    return website().post('upload', payload)
  }
}
